import React, { useState, useEffect } from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider, useQuery, gql } from '@apollo/client';
import attributesData from './Attributes.json';
import TokenCard from './TokenCard';
import './App.css';

const client = new ApolloClient({
  uri: 'https://graphql.mainnet.stargaze-apis.com/graphql',
  cache: new InMemoryCache()
});

const GET_TOKENS = gql`
query Tokens($owner: String, $limit: Int){
  tokens(collectionAddr: "stars1dpu585ulzzgnr352svgkuqfqf0pe3utg3txlj7smzzawc9dx2d5qpl9t36", owner: $owner, limit: $limit) {
    tokens {
      tokenId
      rarityOrder
      name
      collection {
        contractAddress
      }
      media {
        url
    }
  }
}
  names(associatedAddr: $owner) {
    names {
      name
    }
  }
}
`;

function Tokens({ ownerAddr }) {
  const { loading, error, data } = useQuery(GET_TOKENS, {
    variables: { 
      collectionAddr: "stars1dpu585ulzzgnr352svgkuqfqf0pe3utg3txlj7smzzawc9dx2d5qpl9t36",
      limit: 80,
      owner: ownerAddr
    },
    
  });

if (error) {
}
  const [traits, setTraits] = useState({});
  useEffect(() => {
    
    if (data) {
      const fetchTraits = async () => {
        const traitsData = {};
        for (const token of data.tokens.tokens) {
          const tokenId = token.tokenId.replace('#', '');
          const attributes = attributesData ? attributesData.find(item => item['#'] === `#${tokenId}`) : undefined;

          if (attributes) {
            traitsData[token.tokenId] = Object.entries(attributes)
              .filter(([key]) => key !== '#')
              .map(([key, value]) => {
                return { trait_type: key, value: value };
              });
          }
        }
        setTraits(traitsData);
      };
      fetchTraits();
    }
  }, [data]);

  if (!ownerAddr.startsWith('stars')) {
    return <p>This is not a valid Stargaze address.</p>;
  }

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  let filteredTokens = [];
if (data.tokens && data.tokens.tokens) {
  filteredTokens = data.tokens.tokens.filter(token => {
    
    return token.collection.contractAddress === 'stars1dpu585ulzzgnr352svgkuqfqf0pe3utg3txlj7smzzawc9dx2d5qpl9t36';
  });
}
  const factions = [
    'The Iron Nexus',
    'The Ghost Network',
    'The Neon Minds',
    'The Shadow Arbiters',
    'The Veiled Serpents',
  ];
  
  let factionCounts = {};
  factions.forEach(faction => {
    
    factionCounts[faction] = 0;
  });

  filteredTokens.forEach(token => {const factionTrait = traits[token.tokenId]?.find(trait => factions.includes(trait.value));
    if (factionTrait) {
      factionCounts[factionTrait.value]++;
    }
  });
  
  const displayName = data.names.names.length && data.names.names[0].name 
    ? data.names.names[0].name 
    : `${ownerAddr.substring(0, 8)}...${ownerAddr.slice(-5)}`;
  
    return (
      <div>
        <h2>{displayName} Squad Members: {filteredTokens.length}</h2>
        <pre>
          {factions.map(faction => (
            `${faction}: ${factionCounts[faction]}\n`
          ))}
        </pre>
        <div className="tokens-grid">

        {filteredTokens.sort((a, b) => {
  const totalAttributesA = traits[a.tokenId]?.find(trait => trait.trait_type === 'Total Attributes')?.value;
  const totalAttributesB = traits[b.tokenId]?.find(trait => trait.trait_type === 'Total Attributes')?.value;
  return totalAttributesB - totalAttributesA;
}).map((token) => (
  <TokenCard key={token.tokenId} token={token} traits={traits[token.tokenId]} />
))}
        </div>
      </div>
    );
}

function App() {
  const [ownerAddrInput, setOwnerAddrInput] = useState('');
  const [ownerAddrs, setOwnerAddrs] = useState(['stars12hurjn35he797n3fx0dfhjv6lmc39sgfvr4exh']); // Inicie o estado com a carteira desejada

  const handleSearch = () => {
    setOwnerAddrs([ownerAddrInput]);
    setOwnerAddrInput('');
  };

  return (
    <ApolloProvider client={client}>
      <a href="https://supporters.inhabitants.zone/" target="_blank" rel="noopener noreferrer" style={{ position: 'absolute', top: 0, right: 40 }}>
        <h5>Ladder of Power</h5>
      </a>
      <div style={{ paddingBottom: '60px' }}>
        <center>
          <h1>Squad Matrix</h1>
          <h3>Balance and strategize with your personal army's capabilities</h3>
          <input
            type="text"
            placeholder="Enter Stargaze Wallet"
            value={ownerAddrInput}
            onChange={(e) => setOwnerAddrInput(e.target.value)}
          />
          <button onClick={handleSearch}>Search</button>
        </center>
        {ownerAddrs.map((ownerAddr, index) => (
          <Tokens key={index} ownerAddr={ownerAddr} />
        ))}
      </div>
      <footer style={{ position: 'static', bottom: 0, left: 0, right: 0, backgroundColor: '#f5f5f5', textAlign: 'center', padding: '10px' }}>
        Made with ♥️ by <a href="https://inhabitants.zone/" target="_blank" rel="noopener noreferrer">inhabitants.zone</a>
      </footer>
    </ApolloProvider>
  );
}

export default App;