import { Card, CardContent, Typography, Box } from '@mui/material';
import { useStyles } from './TokenCardStyles';

function TokenCard({ token, traits }) {
  const classes = useStyles();
  let httpUrl = '';
  if (token && token.media && token.media.url) {
    httpUrl = token.media.url;
  }

  function formatOtherTraits(traitList) {
    if (!Array.isArray(traitList)) return 'Loading...';
  
    let formattedTraits = [];
    let totalAttributes = '';
  
    const selectedTraits = ['Prestige', 'Authority', 'Influence', 'Resourcefulness'];
  
    traitList.forEach(trait => {
      if (typeof trait === 'object' && trait !== null) {
        const traitType = trait.trait_type?.replace('#', '');
        
        if (traitType === 'Total Attributes') {
          totalAttributes = `${traitType}: ${trait.value}\n`;
        } else if (!selectedTraits.includes(traitType)) {
          if (traitType === 'Heritage') {
            formattedTraits.push(<><strong>{traitType}</strong>: <a href="https://inhabitants.zone" target="_blank" rel="noopener noreferrer">{trait.value}</a><br /></>);
          } else {
            formattedTraits.push(<><strong>{traitType}</strong>: {trait.value}<br /></>);
          }
        }
      }
    });
  
    return <>{formattedTraits}<br />{totalAttributes}</>;
  }
  function formatSelectedTraits(traitList) {
    if (!Array.isArray(traitList)) return '';

    const attributeNames = ['Prestige', 'Authority', 'Influence', 'Resourcefulness'];
    let selectedTraits = [];

    traitList.forEach(trait => {
      if (typeof trait === 'object' && trait !== null) {
        const traitType = trait.trait_type?.replace('#', '');

        if (attributeNames.includes(traitType)) {
          selectedTraits.push(`${traitType}: ${trait.value}`);
        }
      }
    });

    let firstHalf = selectedTraits.slice(0, 2).join(' | ');
    let secondHalf = selectedTraits.slice(2).join(' | ');

    return `${firstHalf}\n${secondHalf}`;
  }

  return (
    <Card className={classes.card}>
      <CardContent>
        <Box display="flex">
          <Box marginRight={2}>
            <a href={httpUrl} target="_blank" rel="noopener noreferrer">
              <img src={httpUrl} className={classes.img} width="200" height="200" alt="1001 inhabitant" />
            </a>
          </Box>
          <Box>
           <Typography variant="h5">
           <a href={token && typeof token.tokenId === 'string' 
              ? `https://www.stargaze.zone/marketplace/${token.collection.contractAddress}/${token.tokenId.replace('#', '')}` 
              : 'https://www.stargaze.zone/marketplace'} 
              target="_blank" 
             rel="noopener noreferrer">
          {token && token.name ? token.name : ''}
           </a>
            </Typography>
            <Typography variant="body1">Rarity {token.rarityOrder}</Typography>
            <Typography variant="body1">
              <pre>{formatOtherTraits(traits)}</pre>
            </Typography>
            <Typography variant="body1">
              <pre>{formatSelectedTraits(traits)}</pre>
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default TokenCard;