// TokenCardStyles.js
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  card: {
    width: '100%',
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  img: { // Adicione este novo estilo
    border: '2px solid #000',
    borderRadius: '10px',
  },
});
